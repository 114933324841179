import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import * as Sentry from '@sentry/angular-ivy'
import { AppModule } from './app/app.module'

// Sentry.init({
//   dsn: 'https://d7ba8a1e79619b3e1c94f80c9af3bb98@o4506266263879680.ingest.sentry.io/4506266274234368',
//   denyUrls: ['gizmo.fiaf.nc', 'localhost'],
//   integrations: [
//     new Sentry.BrowserTracing({
//       tracePropagationTargets: [
//         'fiaf-rsi-gizmo.fiaf-rsi-dev.dev.isi.nc',
//         'fiaf-rsi-gizmo.fiaf-rsi-recette.dev.isi.nc',
//         'fiaf-rsi-gizmo.fiaf-rsi-qual.dev.isi.nc',
//       ],
//       routingInstrumentation: Sentry.routingInstrumentation,
//     }),
//     new Sentry.Replay(),
//   ],
//   tracesSampleRate: 1.0,
//   replaysSessionSampleRate: 0.1,
//   replaysOnErrorSampleRate: 1.0,
// })

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err))
