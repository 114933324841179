import { ActivatedRoute, UrlSegment, Router } from '@angular/router'
import { Component, Input } from '@angular/core'
import { KeycloakProfile } from 'keycloak-js'
import { MenuItem } from 'primeng/api'

import { Environment } from '@src/environments/environment'
import { AuthService } from '@src/core/services/auth.service'
import { TitleService } from '@src/core/services/title.service'

@Component({
  templateUrl: './auth.layout.html',
  styleUrls: ['./auth.layout.scss'],
})
export class AuthLayoutComponent {
  @Input() sidebar: boolean = false

  public loggedIn: boolean = false
  public user: KeycloakProfile = {}
  public logoMenu: string | null = Environment.LOGO
  public titleMenu: string | null = Environment.TITLE
  public sidebarMenuItems: Array<MenuItem> = Environment.LAYOUT_MENU
  public headerMenuItems: Array<MenuItem> = []
  public currentRoute: UrlSegment | null = null
  public pageTitle: string | null = null

  constructor(
    private readonly auth: AuthService,
    private titleService: TitleService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.route.children[this.route.children.length - 1].url.subscribe((data) => {
      this.currentRoute = data[data.length - 1]
    })

    this.titleService.setTitle.asObservable().subscribe((title) => {
      this.pageTitle = title
    })

    this.loggedIn = await this.auth.isLoggedIn()
    if (this.loggedIn) {
      this.user = await this.auth.loadUserProfile()
      this.headerMenuItems = [
        {
          label: `
            <div class="px-4 py-3">
              <p class="my-0 font-bold">${this.fullName}</p>
              <p class="my-0 text-sm">${this.email}</p>
            </div>
          `,
          escape: false,
        },
        {
          label: `
            <div class="flex align-items-center gap-1 px-4 py-2">
              <i class="pi pi-link"></i>
              <a class="no-underline hover:underline text-primary">
                Chartes FIAF
              </a>
            </div>
          `,
          escape: false,
          url: 'https://www.fiaf.nc/boite-a-outils/documents-utiles',
        },
        {
          label: `
            <div class="flex align-items-center gap-1 px-4 py-2">
              <i class="pi pi-link"></i>
              <a class="no-underline hover:underline text-primary">
                CGU GIZMO
              </a>
            </div>
          `,
          escape: false,
          url: 'https://www.fiaf.nc/actualites/actualites/594-cgu-gizmo',
        },
        {
          label: `
            <div class="flex align-items-center gap-1 px-4 py-3">
              <i class="pi pi-sign-out"></i>
              <p class="my-0">Déconnexion</p>
            </div>
          `,
          escape: false,
          command: () => this.onLogout(),
        },
      ]
    }
  }

  public get currentPage(): string {
    const currentUrl = this.router.url
    const matchingItem =
      this.sidebarMenuItems
        .reduce((items: any, item: MenuItem) => {
          return item?.items ? [...items, ...item.items] : items
        }, [])
        .find((item: MenuItem) => currentUrl.includes(item.routerLink)) || null
    return matchingItem ? matchingItem.label : this.pageTitle
  }

  public get fullName(): string {
    return this.user.firstName + ' ' + this.user.lastName
  }

  public get email(): string {
    return this.user.email ?? ''
  }

  public onLogout(): void {
    this.auth.logout()
  }

  public onSidebar() {
    this.sidebar = !this.sidebar
  }
}
