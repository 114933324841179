import { Injectable } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { BehaviorSubject, merge, Observable } from 'rxjs'
import { filter, map, tap } from 'rxjs/operators'

@Injectable({ providedIn: 'root' })
export class TitleService {
  public setTitle = new BehaviorSubject<string>('')

  private titleRoute$: Observable<string | undefined> = this.router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    map(() => this.getTitle(this.activatedRoute.firstChild))
  )

  private titleState$ = merge(this.setTitle, this.titleRoute$).pipe(
    filter((title) => title !== undefined),
    tap((title) => this.titleService.setTitle(`FIAF ${title}`))
  )

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {
    this.titleState$.subscribe()
  }

  private getTitle(activatedRoute: ActivatedRoute | null): string | undefined {
    let title = ''
    while (activatedRoute) {
      const pageTitle = activatedRoute.snapshot.data['pageTitle']
      title += pageTitle ? (title ? ' - ' : '') + activatedRoute.snapshot.data['pageTitle'] : ''
      activatedRoute = activatedRoute.firstChild
    }
    return title
  }
}
