import { Component } from '@angular/core'

import { TitleService } from '@src/core/services/title.service'

@Component({
  selector: 'rsi-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(public titleService: TitleService) {}
}
