export const Environment = {
  API_PATH: 'api/office',
  TITLE: 'Espace Conseillère',
  LOGO: 'assets/logo-menu-gizmo.svg',
  LAYOUT_MENU: [
    {
      separator: true,
    },
    {
      label: 'Demande de financement',
      items: [{ label: 'Consulter les demandes', routerLink: 'demandes' }],
    },
    {
      label: 'Enveloppe FIAF',
      items: [{ label: 'Consulter les enveloppes', routerLink: 'enveloppes' }],
    },
    {
      label: 'Versements',
      items: [{ label: 'Consulter les versements', routerLink: 'versements' }],
    },
    {
      label: 'Structures',
      items: [{ label: 'Consulter les structures', routerLink: 'structures' }],
    },
    {
      label: 'Formateurs',
      items: [{ label: 'Consulter les formateurs', routerLink: 'formateurs' }],
    },
    {
      label: 'Paiements',
      items: [{ label: 'Consulter les lots de paiement', routerLink: 'paiements' }],
    },
    {
      label: 'Comptabilisations',
      items: [{ label: 'Consulter les lots de comptabilisation', routerLink: 'comptabilisations' }],
    },
    {
      label: 'Rapports',
      items: [{ label: 'Rapports ESFA', routerLink: 'rapports' }],
    },
    {
      label: 'Comptes',
      items: [{ label: 'Gestion des comptes', routerLink: 'comptes' }],
    },
  ],
}
