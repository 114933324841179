import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile, KeycloakTokenParsed, KeycloakInitOptions } from 'keycloak-js';

import { keycloakInitializer } from '../keycloak-initializer';

@Injectable()
export class AuthService {
    constructor(private keycloakService: KeycloakService) { }

    public getLoggedUser(): KeycloakTokenParsed | undefined {
        try {
            const userDetails: KeycloakTokenParsed | undefined = this.keycloakService.getKeycloakInstance()
                .idTokenParsed;
            return userDetails;
        } catch (e) {
            return undefined;
        }
    }

    public isLoggedIn(): Promise<boolean> {
        return this.keycloakService.isLoggedIn();
    }

    public loadUserProfile(): Promise<KeycloakProfile> {
        return this.keycloakService.loadUserProfile();
    }

    public login(): void {
        this.keycloakService.login();
    }

    public logout(): void {
        localStorage.removeItem("KC_TOKEN");
        this.keycloakService.logout(window.location.origin);
    }

    public redirectToProfile(): void {
        this.keycloakService.getKeycloakInstance().accountManagement();
    }

    public getRoles(): string[] {
        return this.keycloakService.getUserRoles();
    }

}
