import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { AuthGuard } from '@src/core/guards/auth.guard'
import { AuthLayoutComponent } from '@src/layouts/auth/auth.layout'
import { LayoutsModule } from '@src/layouts/layouts.module'

const routes: Routes = [
  {
    path: '',
    redirectTo: 'conseillere',
    pathMatch: 'full',
  },
  {
    path: 'conseillere',
    component: AuthLayoutComponent,
    data: { roles: ['fiaf-operator'] },
    loadChildren: () => import('./views/account/account.module').then((m) => m.AccountModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'financement',
    data: { roles: ['fiaf-operator'], pageTitle: 'Financement' },
    loadChildren: () => import('./views/funding/funding.module').then((m) => m.FundingModule),
    canActivate: [AuthGuard],
  },
  { path: '**', redirectTo: 'conseillere' },
]

@NgModule({
  imports: [LayoutsModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
