import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { HttpClientModule } from '@angular/common/http'

import { MenuModule } from 'primeng/menu'
import { ButtonModule } from 'primeng/button'
import { MenubarModule } from 'primeng/menubar'
import { SidebarModule } from 'primeng/sidebar'
import { DividerModule } from 'primeng/divider'
import { DropdownModule } from 'primeng/dropdown'

import { AuthLayoutComponent } from './auth/auth.layout'

@NgModule({
  imports: [
    RouterModule,
    HttpClientModule,
    MenuModule,
    ButtonModule,
    MenubarModule,
    SidebarModule,
    DividerModule,
    DropdownModule,
  ],
  declarations: [AuthLayoutComponent],
})
export class LayoutsModule {}
