<main class="flex justify-content-end">
    <div class="flex flex-column transition-width transition-linear transition-duration-100"
        [style.width]="sidebar ? 'calc(100% - 336px)': '100%'">
        <header class="header flex shadow-1">
            <p-button (onClick)="onSidebar()" icon="pi pi-align-left"
                styleClass="p-button-lg border-noround"></p-button>
            <div class="flex flex-1 justify-content-between align-items-center">
                <div class="flex align-items-center gap-3">
                    <h3 class="text-color-secondary my-0 mx-3">{{ currentPage }}</h3>
                </div>
                <div class="flex h-full align-items-center">
                    <!--<i class="pi pi-bell text-xl text-primary"></i>
                    <p-divider layout="vertical" styleClass="py-2"></p-divider>-->
                    <div (click)="menu.toggle($event)" class="cursor-pointer pl-3 pr-5">
                        <p class="font-bold text-color-secondary my-0">{{ fullName }}</p>
                        <p class="text-sm my-0">{{ titleMenu }}</p>
                    </div>
                    <p-menu #menu [model]="headerMenuItems" [popup]="true"
                        styleClass="border-noround p-0 my-2"></p-menu>
                </div>
            </div>
        </header>
        <div class="sidebar flex overflow-y-auto" style="height: calc(100vh - 60px)">
            <p-sidebar [(visible)]="sidebar" position="left" [showCloseIcon]="false" [dismissible]="false"
                [transitionOptions]="'100ms linear'" [modal]="false" styleClass="w-21rem">
                <ng-template pTemplate="header">
                    <img width="180px" [src]="logoMenu" alt="FIAF">
                </ng-template>
                <ng-template pTemplate="content">
                    <p-divider></p-divider>
                    <a class="home no-underline block" [routerLink]="['/']" [class.selected]="currentPage == 'Accueil'">
                        <i class="pi pi-home"></i>
                        Accueil
                    </a>
                    <p-menu [model]="sidebarMenuItems" styleClass="w-full border-none"></p-menu>
                </ng-template>
            </p-sidebar>
            <div class="flex-auto">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</main>